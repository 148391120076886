var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "b-modal",
        {
          attrs: {
            id: "add-custom-compliance-event-modal",
            title: "",
            "header-class": "no-borders",
            "hide-footer": "",
          },
          on: {
            hidden: function ($event) {
              return _vm.$emit("hidden")
            },
          },
          model: {
            value: _vm.isVisible,
            callback: function ($$v) {
              _vm.isVisible = $$v
            },
            expression: "isVisible",
          },
        },
        [
          _c("h2", { staticClass: "modal-title" }, [
            _vm._v("\n    Add Custom Event\n  "),
          ]),
          _c(
            "b-form-group",
            { attrs: { label: "Company" } },
            [
              _c(
                "b-form-select",
                {
                  attrs: {
                    "data-cy": "select",
                    options: _vm.companies.map((element) => {
                      return { text: element.name, value: element.id }
                    }),
                  },
                  model: {
                    value: _vm.company,
                    callback: function ($$v) {
                      _vm.company = $$v
                    },
                    expression: "company",
                  },
                },
                [
                  _c("template", { slot: "first" }, [
                    _c(
                      "option",
                      { attrs: { disabled: "" }, domProps: { value: null } },
                      [_vm._v("\n          -- Select a Company --\n        ")]
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: "Date" } },
            [
              _c("b-form-input", {
                attrs: { type: "date" },
                model: {
                  value: _vm.date,
                  callback: function ($$v) {
                    _vm.date = $$v
                  },
                  expression: "date",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: "Description" } },
            [
              _c("b-form-textarea", {
                model: {
                  value: _vm.description,
                  callback: function ($$v) {
                    _vm.description = $$v
                  },
                  expression: "description",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: "Frequency" } },
            [
              _c(
                "b-form-radio",
                {
                  attrs: { name: "customEventFrequency", value: "oneshot" },
                  model: {
                    value: _vm.customEventFrequency,
                    callback: function ($$v) {
                      _vm.customEventFrequency = $$v
                    },
                    expression: "customEventFrequency",
                  },
                },
                [_vm._v("\n      Just Once\n    ")]
              ),
              _c(
                "b-form-radio",
                {
                  attrs: { name: "customEventFrequency", value: "annual" },
                  model: {
                    value: _vm.customEventFrequency,
                    callback: function ($$v) {
                      _vm.customEventFrequency = $$v
                    },
                    expression: "customEventFrequency",
                  },
                },
                [_vm._v("\n      Annual\n    ")]
              ),
              _c(
                "b-form-radio",
                {
                  attrs: { name: "customEventFrequency", value: "biennial" },
                  model: {
                    value: _vm.customEventFrequency,
                    callback: function ($$v) {
                      _vm.customEventFrequency = $$v
                    },
                    expression: "customEventFrequency",
                  },
                },
                [_vm._v("\n      Biennial\n    ")]
              ),
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "mx-auto mt-4",
              staticStyle: { width: "100%" },
              attrs: {
                variant: "primary",
                disabled: !_vm.isValid,
                "aria-label": "continue button",
              },
              on: { click: _vm.submit },
            },
            [_vm._v("\n    Continue\n  ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }