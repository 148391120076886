<template>
  <b-modal
    v-if="loaded"
    id="add-custom-compliance-event-modal"
    v-model="isVisible"
    title
    header-class="no-borders"
    hide-footer
    @hidden="$emit('hidden')"
  >
    <h2 class="modal-title">
      Add Custom Event
    </h2>
    <b-form-group label="Company">
      <b-form-select
        v-model="company"
        data-cy="select"
        :options="companies.map(element => {return { text: element.name, value: element.id }})"
      >
        <template slot="first">
          <option :value="null" disabled>
            -- Select a Company --
          </option>
        </template>
      </b-form-select>
    </b-form-group>
    <b-form-group label="Date">
      <b-form-input v-model="date" type="date" />
    </b-form-group>
    <b-form-group label="Description">
      <b-form-textarea v-model="description" />
    </b-form-group>
    <b-form-group label="Frequency">
      <b-form-radio v-model="customEventFrequency" name="customEventFrequency" value="oneshot">
        Just Once
      </b-form-radio>
      <b-form-radio v-model="customEventFrequency" name="customEventFrequency" value="annual">
        Annual
      </b-form-radio>
      <b-form-radio v-model="customEventFrequency" name="customEventFrequency" value="biennial">
        Biennial
      </b-form-radio>
    </b-form-group>
    <b-button
      variant="primary"
      :disabled="!isValid"
      class="mx-auto mt-4"
      style="width:100%"
      aria-label="continue button"
      @click="submit"
    >
      Continue
    </b-button>
  </b-modal>
</template>

<script>
import { isNotEmptyOrNull } from '@/common/modules/strings'
import { usStates } from '@/common/modules/usStates'
import axiosClient from '../http'
const { DateTime } = require('luxon')

export default {
  name: 'AddEventModal',
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      company: '',
      companies: [],
      name: '',
      date: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      description: '',
      customEventFrequency: '',
      isVisible: false,
    }
  },
  computed: {
    formData() {
      const formattedDate = DateTime.fromFormat(this.date, 'yyyy-MM-dd')
      return {
        company_id: this.company,
        due_date: formattedDate,
        description: this.description,
        recurrence_rule: this.customEventFrequency,
      }
    },
    usStates() {
      return usStates
    },
    isValid() {
      return (
        isNotEmptyOrNull(this.date) &&
        isNotEmptyOrNull(this.description) &&
        isNotEmptyOrNull(this.customEventFrequency)
      )
    },
  },
  watch: {
    visible() {
      this.isVisible = this.$props.visible
    },
  },
  async mounted() {
    const response = await axiosClient.get('client/companies', { params: { limit: 5000 } })

    this.companies = response.data.result
  },
  methods: {
    async submit() {
      await axiosClient.post('client/generic_compliance_events', {}, { data: { generic_compliance_event: this.formData } } )

      this.$emit('hidden')
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-title {
  margin-top: -1em;
  margin-bottom: 1em;
  text-align: center;
}
</style>
